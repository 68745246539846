<template>
  <div :id="`vehicle-${vehicleState?.name + '-' + vehicleId}-short-view`"
       class="flex-col h-full w-full">
    <div class="flex flex-row m-2 cursor-pointer" @click="expanded = !expanded">
      <div :class="[!vehicleState.connected ?
                            'text-transparent' : getVehicleCheckColor(vehicleState),
                            'h-2 w-2 min-w-[0.5rem] my-4 px-0 rounded-full flex items-center']"/>

      <p class="flex p-2 color_enabled items-center" style=" vertical-align: middle;">{{
          vehicleState?.name
        }}-{{ vehicleId }} 
        <ExclamationTriangleIcon v-if="!expanded && typeof vehicleState?.agvPosition?.positionInitialized === 'boolean' && !vehicleState?.agvPosition?.positionInitialized" class="w-5 h-5 ml-1 text-orange-500"/>
      </p>
      <div class="flex flex-grow"/>
      <p class="p-2 color_enabled align-bottom" style="font-size: 0.9rem;  line-height:25px;">
        {{ $t("str_last_updated") }}:
        {{ formatTimeString(vehicleState?.timestamp) }}</p>
    </div>
    <!--    change bg-transparent to bg-red-500 to see when vehicle makes base request-->
    <div :class="[vehicleState?.newBaseRequest ? 'bg-transparent' : 'bg-transparent', 'flex flex-col m-2']"
         v-if="expanded">
      <ul>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_show_on_map") }}:
          </p>
          <div class="flex flex-grow"/>
          <Switch
              @click="toggleVehicleVisibility()"
              v-model="vehicleState.show_on_map"
              :class="vehicleState.show_on_map ? 'bg-orange-100' : 'bg-teal-900'"
              class="relative h-4 w-8 mx-2 shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-100 ease-in-out">
            <span class="sr-only">{{ $t("str_show_on_map") }}</span>
            <span
                aria-hidden="true"
                :class="vehicleState.show_on_map ? 'translate-x-2' : '-translate-x-2'"
                class="inline-block h-4 w-4 transform rounded-full -translate-y-0.5 bg-white shadow-lg transition duration-100 ease-in-out"
            />
          </Switch>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_order_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.orderId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_node_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.lastNodeId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_sequence_id") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.lastNodeSequenceId }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_localized") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled flex items-center">
            <ExclamationTriangleIcon v-if="typeof vehicleState?.agvPosition?.positionInitialized === 'boolean' && !vehicleState?.agvPosition?.positionInitialized" class=" mr-1 w-5 h-5 text-orange-500"/>
            {{ vehicleState?.agvPosition?.positionInitialized ?? false }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_driving") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.driving }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_paused") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.paused }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_new_base_request") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.newBaseRequest }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_has_released_edges") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.edgeStates.filter((value: EdgeState) => value.released).length }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_has_released_nodes") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ vehicleState?.nodeStates.filter((value: NodeState) => value.released).length }}
          </p>
        </li>

        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_last_battery") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ Math.trunc(vehicleState?.batteryState?.batteryCharge) }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 truncate text-sm color_enabled font-light">
            {{ $t("str_current_destination[0]") }}:
          </p>
          <div class="flex flex-grow"/>
          <p class="mx-2 mb-2 text-sm color_enabled">
            {{ $t("str_current_destination[1]") }} {{ vehicleState?.nodeStates?.at(-1)?.node_id }}, {{
              nodesLookup?.get(Number(vehicleState?.nodeStates?.at(-1)?.node_id))?.nodeDescription
            }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <p class="ml-4 mb-2 mt-4 truncate text-sm color_disabled font-light">
            {{ $t("str_instant_actions") }}
          </p>
        </li>
        <li class="flex flex-row flex-grow">
          <div class="w-full mx-4 mb-2">
            <Dropdown
                :elem-list="actionTypes"
                @selectedEl="setInstantActionType"/>
          </div>
        </li>
        <div v-if="instantAction.actionParameters.length !== 0" v-for="actionParam in instantAction.actionParameters">
          <ul>
            <li class="flex flex-row flex-grow">
              <p class="mx-4 mb-2 flex justify-between" style="width: calc(100% - 32px);">
                <span v-if="actionParam.isEditing"><input class="w-full px-2" v-model="actionParam.key"></span>
                <span style="max-width: calc(100% - 16px); overflow:hidden; text-overflow: ellipsis;" v-if="!actionParam.isEditing" class="color_enabled">{{ actionParam.key }}:</span>
                <button type="button"
                        v-bind:class="['align-self-end color_enabled relative px-2 inline-flex items-center rounded-md text-sm font-medium outline-none']"
                        v-on:click="actionParam.isEditing = !actionParam.isEditing">
                  <PencilIcon class="-ml-1 -mr-1 h-4 w-4 " aria-hidden="true"/>
                </button>
              </p>
            </li>
            <li class="flex flex-row flex-grow">
              <p class="mx-4 mb-2">
                <input class="w-full px-2" v-model="actionParam.value">
              </p>
            </li>
          </ul>
        </div>
        <li class="flex flex-row flex-grow mx-8 mb-4">
          <button @click="sendInstantAction()" class="mx-8 border w-full rounded-md py-1 button2">
            {{ $t("str_send_instant_action") }}
          </button>
        </li>
      </ul>
    </div>
  </div>

</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import {formatTimeString, getVehicleCheckColor} from "@/utils";
import {Switch} from '@headlessui/vue'
import Dropdown from "@/components/Dropdown.vue";
import {EdgeState, NodeState} from "../../dtos/VehicleState";</script>

<script lang="ts">
import { useConfig } from "@/main"
import { defineComponent } from 'vue'
import {publishInstantAction} from "@/network/MqttManager";
import {VehicleState} from "@/dtos/VehicleState";
import * as StorageManager from "@/datamanagers/StorageManager";

export default defineComponent (
  {
  name: "VehicleShortView",
  data() {
    return {
      actions: new Array(),
      actionTypes: new Array(),
      expanded: false,
      instantAction: {
        action: {
          actionId: 0,
          actionDescription: "",
          actionType: "",
          blockingType: "",
        },
        actionParameters: new Array()
      },
    }
  },
  props: {
    vehicleId: {
      type: String,
      default: "0"
    },
    vehicleState: {
      type: VehicleState,
      default: new VehicleState({})
    },
    nodesLookup: {
      type: Map,
      default: {}
    }
  },
  methods: {
    getActions() {
      var actions = StorageManager.getActions().filter((action: any) => action.scope_instant == true)
      this.actions = actions;
      this.actionTypes = actions.map((a:any)=>JSON.parse(JSON.stringify(a)).actionType)
    },
    setInstantActionType(actionType: any) {
      const refAction = this.actions.find(value => value.actionType == actionType)
      this.instantAction.action = refAction
      this.instantAction.actionParameters = refAction.actionParameters
    },
    sendInstantAction() {
      var instantActionMsg = {
        actionId: this.instantAction.action.actionId,
        actionType: this.instantAction.action.actionType,
        actionDescription: this.instantAction.action.actionDescription,
        blockingType: this.instantAction.action.blockingType,
        actionParameters: this.instantAction.actionParameters
      }
      publishInstantAction(useConfig().config.configuration.mqtt,
          window.location.hostname,
          window.location.protocol,
          this.vehicleId,
          instantActionMsg)
    },
    toggleVehicleVisibility() {
      this.vehicleState.show_on_map = !this.vehicleState?.show_on_map
    }
  },
  created() {
    this.getActions();
  }
}
)
</script>

<style scoped>

</style>
