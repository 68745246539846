<template>
  <div ref="fullRoutesPopup">
    <div :class="{
      'w-full bg-opacity-75': showFullRoutePopup,
      'w-0 bg-opacity-0': !showFullRoutePopup,
      'fixed inset-0 bg-gray-900 transition-colors': true
    }"></div>
    <div v-if="showFullRoutePopup" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 z-10 pl-[70px] md:pl-0 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          id="fullRoutePopupWrapper" v-on:click="outsideClickHandler">
          <div id="dialogPanel"
            class="relative transform overflow-hidden rounded-lg shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="mx-auto w-full color_background_dark rounded-xl transition-all">
              <main class="my-4 relative">
                <div class="button2 flex-col px-4 py-4" @click="() => { }">
                  <p class="color_enabled text-xl text-left mb-2">{{
                    `${$t('str_full_route_for_order')} ${order?.orderId}`
                  }}</p>
                  <ul role="list" class="mx-auto">
                    <li v-for="(node, nodeIdx) in order?.nodes ?? []">
                      <div class="relative pb-2">
                        <span v-if="nodeIdx !== (order.nodes?.length || 1) - 1"
                          class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                        <div class="relative flex space-x-3">
                          <div>
                            <span
                              :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                              <CheckCircleIcon v-if="order.nodes.length === 1 || nodeIdx === order.nodes.length - 1"
                                :class="['h-5 w-5 color_disabled', (_checkKeyNode(node) ? 'text-orange-400' : '')]"
                                aria-hidden="true" />
                              <ArrowDownCircleIcon v-else
                                :class="['h-5 w-5 color_disabled', (_checkKeyNode(node) ? 'text-orange-400' : '')]"
                                aria-hidden="true" />
                            </span>
                          </div>
                          <div class="flex min-w-0 space-x-4 pt-1.5">
                            <p :class="['color_enabled text-xl text-left', (_checkKeyNode(node) ? 'font-bold' : '')]">
                              {{ node?.nodeId }}: {{ node?.nodeDescription?.length ? node?.nodeDescription : '-' }}
                            </p>
                          </div>
                        </div>
                        <ul class="pl-16 mt-2 list-disc">
                          <li v-for="item in _getNodeActions(node)" class="color_disabled">
                            <p class="color_disabled font-bold text-sm text-left">
                              {{ `${item.action.actionType}${item.actionParameters?.length ? ':' : ''}` }}
                            <p v-for="actionParam in item.actionParameters" class="text-sm font-normal text-left">
                              {{ `- ${actionParam.key}: ${actionParam.value}` }}
                            </p>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <button type="button" v-on:click="closeModal" class="button2 border rounded-lg w-full p-1">{{
                    $t('str_full_route_close') }}</button>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowDownCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import { defineComponent } from "vue";
import * as ApiManager from "../../network/ApiManager";
import * as AlertManager from '@/datamanagers/AlertsManager';
import { prod_safe_log } from '@/utils';


export default defineComponent({
  name: "FullRoutePopup",
  components: {
    ArrowDownCircleIcon,
    CheckCircleIcon
  },
  data() {
    return {
      showFullRoutePopup: false,
      order: null as any,
    }
  },
  methods: {
    outsideClickHandler(e: any) {
      if (e.target.id === "fullRoutePopupWrapper") {
        this.closeModal();
      }
    },
    closeModal() {
      this.showFullRoutePopup = false;
      this.order = null;
      this.$emit('closeModal');
    },
    show(order: any) {
      this.order = order
      this._getAndReplaceOrderRequestAndOrder(order);
      return order;
    },
    _getAndReplaceOrderRequestAndOrder(order: any) {
      let orderRequestId = order.orderRequestId
      let orderId = order.orderId

      const promises = [];
      if (!!orderRequestId) {
        const orderRequestsPromise = new Promise((resolve, reject) => ApiManager.getOrderRequest(orderRequestId,
          () => {
            // @ts-ignore
            AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_order_request"), this.$t("str_try_again")))
            prod_safe_log('orderRequest could not be requested')
            reject('loading error');
          },
          (data: any) => {
            this.order.key_nodes = data.order_request.nodes
            resolve('loading sucess')
          }
        ))
        promises.push(orderRequestsPromise)
      }

      if (!!orderId) {
        const orderPromise = new Promise((resolve, reject) => ApiManager.getOrder(orderId,
          () => {
            // @ts-ignore
            AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_order"), this.$t("str_try_again")))
            prod_safe_log('order could not be requested')
            reject('loading error');
          },
          (data: any) => {
            data.order.nodes.forEach((element: any) => {
              element = Object.assign(element, element.node)
            });
            this.order.nodes = data.order.nodes
            resolve('loading sucess')
          }
        ))
        promises.push(orderPromise)
      }

      Promise.all(promises)
        .then(
          () => {
            this.showFullRoutePopup = true;
          })
        .catch((error) => {
          console.error(error.message);
        });
    },
    _checkKeyNode(node: any) {
      if (this.order?.key_nodes?.length) {
        const keyNodesMatch = this.order.key_nodes.filter((key_node: any) => {
          return key_node.nodeId === node.nodeId
        })
        return !!keyNodesMatch.length;
      }
      return false
    },
    _getNodeActions(node: any) {
      const filtered_key_node = this.order?.key_nodes?.filter((key_node: any) => key_node.nodeId === node.nodeId);
      if (filtered_key_node?.length)
        return filtered_key_node[0].actions;
      return node.actions
    },
  }
})


</script>

<style scoped></style>
