<template>
  <!--  top info (connection)-->
  <div class="flex justify-between my-4 mx-8">
    <div class="items-center flex flex-row -ml-6" @click="() => hiddenActivation()">
      <div :class="[!vehicleStates.get(vehicle_id)?.connected ?
                            'text-transparent' : getVehicleCheckColor(vehicleStates.get(vehicle_id)),
                            'h-2 w-2 rounded-full mx-2']"/>
      <p class="color_enabled uppercase text-sm text-center">{{ vehicleStates.get(vehicle_id)?.name }}-{{
          vehicle_id
        }}</p>
    </div>
    <div class="items-center flex flex-row ">
      <ChartBarIcon class="h-6 lg:w-6 w-0 color_disabled mx-2" aria-hidden="true"/>
      <p class="color_enabled uppercase text-sm text-center">{{
          vehicleStates.get(vehicle_id)?.connected ? $t("str_vehicle_online") :
              $t("str_vehicle_offline")
        }}</p>
    </div>
    <div class="items-center flex flex-row ">
      <MapPinIcon v-if="vehicleStates.get(vehicle_id)?.agvPosition?.positionInitialized" class="h-6 lg:w-6 w-0 color_disabled mx-2" aria-hidden="true"/>
      <ExclamationTriangleIconSolid v-if="typeof vehicleStates.get(vehicle_id)?.agvPosition?.positionInitialized === 'boolean' && !vehicleStates.get(vehicle_id)?.agvPosition?.positionInitialized" class="w-5 h-5 text-orange-500 mx-2"/>
      <p class="color_enabled uppercase text-sm text-center">{{
          vehicleStates.get(vehicle_id)?.agvPosition?.positionInitialized ?
              $t("str_vehicle_localized") :
              $t("str_vehicle_not_localized")
        }}</p>
    </div>

    <div class="items-center flex flex-row ">
      <ClipboardDocumentCheckIcon class="h-6 lg:w-6 w-0 color_disabled mx-2" aria-hidden="true"/>
      <p class="color_enabled uppercase text-sm text-center">{{ $t("str_active_order") }}: {{
          vehicleStates.get(vehicle_id)?.hasActiveOrder() ?
              vehicleStates.get(vehicle_id)?.orderId : '-'
        }}</p>
    </div>
    <div class="items-center flex flex-row ">
      <p class="color_disabled uppercase text-center">
        {{ formatTimeString(Number(vehicleStates.get(vehicle_id)?.connection?.timestamp)) }}</p>
    </div>
  </div>
  <div class="color_background_disabled w-full" style="height: 1px"/>
  <!--  vehicle status-->
  <div class="flex justify-between my-4 mx-8">
    <h1 @click="resetPreassignedOrder" class="color_disabled uppercase text-sm">
      {{ $t('str_vehicle_status') }}</h1>
    <p class="color_disabled uppercase ">
      {{ formatTimeString(Number(vehicleStates.get(vehicle_id)?.timestamp)) }}</p>
  </div>
  <ul role="list" class="my-4 mx-8 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
    <!--    Vehicle order details  -->
    <li class="col-span-1 row-span-2 flex-col shadow-sm">
      <div class="flex mb-2">
        <ClipboardDocumentCheckIcon class="h-6 w-6 color_disabled mr-4" aria-hidden="true"/>
        <h2 class="flex items-center justify-center color_disabled uppercase text-sm">{{
            $t('str_vehicle_order')
          }}
        </h2>
      </div>
      <div v-if="vehicleStates.get(vehicle_id)?.hasActiveOrder()" class="flex flex-row justify-between mx-10 mb-2">
        <button @click="confirmForceOrderUpdate(() => forceOrderUpdate(vehicle_id))" class="border w-full rounded-md py-1 button2">
          {{ $t("str_force_order_update") }}
        </button>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_last_order_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.orderId ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_order_update_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.orderUpdateId ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_last_node_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.lastNodeId ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_last_sequence_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.lastNodeSequenceId ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_node_states') }}:
        </p>
        <p v-if="vehicleStates.get(vehicle_id)?.nodeStates.length==0">-</p>
      </div>
      <div v-for="node in vehicleStates.get(vehicle_id)?.nodeStates"
           class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
        <p>{{ $t('str_vehicle_node_id') }} {{ node.node_id }}, #{{ node.sequence_id }},
          {{ nodes.get(Number(node.node_id))?.nodeDescription }}</p>
        <p> {{ node.released ? $t('str_released') : $t('str_not_released') }} </p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_edge_states') }}:
        </p>
        <p v-if="vehicleStates.get(vehicle_id)?.edgeStates.length==0">-</p>
      </div>
      <div v-for="edge in vehicleStates.get(vehicle_id)?.edgeStates"
           class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
        <p>{{ $t('str_vehicle_edge_id') }} {{ edge.edgeId }}, #{{ edge.sequenceId }}</p>
        <p> {{ edge.released ? $t('str_released') : $t('str_not_released') }} </p>
      </div>

      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_action_states') }}:
        </p>
        <p v-if="vehicleStates.get(vehicle_id)?.actionStates.length==0">-</p>
      </div>
      <div v-for="action in vehicleStates.get(vehicle_id)?.actionStates"
           class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
        <p>{{ action.actionType }} {{ action.actionId }}</p>
        <p> {{ action.actionStatus }} </p>
      </div>

      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_loads') }}:
        </p>
        <p v-if="vehicleStates.get(vehicle_id)?.loads.length==0">-</p>
      </div>
      <div v-for="load in vehicleStates.get(vehicle_id)?.loads"
           class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
        <p>{{ load.loadId }}, {{ load.loadType }}</p>
      </div>

    </li>

    <!--    Vehicle general info  -->
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <div class="flex mb-2">
        <Squares2X2Icon class="h-6 w-6 color_disabled mr-4" aria-hidden="true"/>
        <h2 class="flex items-center justify-center color_disabled uppercase text-sm">{{
            $t('str_vehicle_general_info')
          }}
        </h2>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_serial_number') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.serialNumber ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_manufacturer') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.manufacturer ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_protocol_version') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.version ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_last_driving') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.driving === undefined || vehicleStates.get(vehicle_id)?.driving === null ? '-' : vehicleStates.get(vehicle_id)?.driving ? $t('str_yes') : $t('str_no') }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_last_paused') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.paused === undefined || vehicleStates.get(vehicle_id)?.paused === null ? '-' : vehicleStates.get(vehicle_id)?.driving ? $t('str_yes') : $t('str_no') }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_operating_mode') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.operatingMode ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_battery_charge') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.batteryState?.batteryCharge !== null && vehicleStates.get(vehicle_id)?.batteryState?.batteryCharge !== undefined ? vehicleStates.get(vehicle_id)?.batteryState?.batteryCharge + '%' : '-' }}</p>
      </div>
    </li>

    <!--    Vehicle errors  -->
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <div class="flex mb-2">
        <ExclamationTriangleIcon class="h-6 w-6 color_disabled mr-4" aria-hidden="true"/>
        <h2 class="flex items-center justify-center color_disabled uppercase text-sm">{{
            $t('str_vehicle_errors')
          }}
        </h2>
      </div>
      <div v-if="vehicleStates.get(vehicle_id)?.errors.length==0"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_no_errors') }}
        </p>
      </div>
      <div v-else v-for="error in vehicleStates.get(vehicle_id)?.errors">
        <div class="flex flex-row justify-between mx-10 color_enabled">
          <p class="flex flex-col">
            {{ error.type }}:
          </p>
        </div>
        <div v-for="errorRef in error.references"
             class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
          <p>{{ errorRef.referenceKey }}</p>
          <p> {{ errorRef.referenceValue }} </p>
        </div>
      </div>
    </li>

    <!--    Vehicle position  -->
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <div class="flex mb-2">
        <MapPinIcon class="h-6 w-6 color_disabled mr-4" aria-hidden="true"/>
        <h2 class="flex items-center justify-center color_disabled uppercase text-sm">{{
            $t('str_vehicle_position')
          }}
        </h2>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_localized') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.positionInitialized ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          x:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.x?.toFixed(2) ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          y:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.y?.toFixed(2) ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          theta:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.theta?.toFixed(2) ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_map_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.mapId ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_map_description') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.agvPosition?.mapDescription ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_zone_set_id') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.zoneSetId ?? '-' }}</p>
      </div>
    </li>

    <!--    Vehicle Info  -->
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <div class="flex mb-2">
        <InformationCircleIcon class="h-6 w-6 color_disabled mr-4" aria-hidden="true"/>
        <h2 class="flex items-center justify-center color_disabled uppercase text-sm">{{
            $t('str_vehicle_info')
          }}
        </h2>
      </div>
      <div v-if="vehicleStates.get(vehicle_id)?.information.length==0"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_vehicle_no_info') }}
        </p>
      </div>
      <div v-else v-for="info in vehicleStates.get(vehicle_id)?.information">
        <div class="flex flex-row justify-between mx-10 color_enabled">
          <p class="flex flex-col">
            {{ info.type }}:
          </p>
        </div>
        <div v-for="infoRef in info.references"
             class="text-sm ml-14 flex flex-row justify-between mx-10 color_enabled">
          <p>{{ infoRef.referenceKey }}</p>
          <p> {{ infoRef.referenceValue }} </p>
        </div>
      </div>
    </li>
  </ul>

  <div class="color_background_disabled w-full" style="height: 1px"/>

  <!--  vehicle factsheets-->
  <div class="flex justify-between my-4 mx-8">
    <h1 class="color_disabled uppercase text-sm">
      {{ $t('str_factsheet') }}</h1>
    <p class="color_disabled uppercase ">
      {{ formatTimeString(Number(vehicleStates.get(vehicle_id)?.factsheet?.timestamp)) }}</p>
  </div>
  <!--    Type specification  -->
  <ul role="list" class="my-4 mx-8 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_type_specification')
        }}
      </h2>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_series_name') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.seriesName ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_series_description') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.seriesDescription ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_agv_class') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.agvClass ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_agv_kinematic') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.agvKinematic ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_max_load') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.maxLoadMass ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_localization_types') }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification.localizationTypes?.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification.localizationTypes.join(', ') : '-'
          }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_navigation_types') }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.navigationTypes.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.typeSpecification?.navigationTypes.join(', ') : '-'
          }}</p>
      </div>

    </li>
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_physical_parameters')
        }}
      </h2>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_speed_min') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.speedMin ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_speed_max') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.speedMax ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_acceleration_max') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.accelerationMax ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_deceleration_max') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.decelerationMax ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_height_min') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.heightMin ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_height_max') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.heightMax ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_width') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.width ?? '-' }}</p>
      </div>
      <div class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ $t('str_factsheet_length') }}:
        </p>
        <p>{{ vehicleStates.get(vehicle_id)?.factsheet?.physicalParameters?.length ?? '-' }}</p>
      </div>
    </li>
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_protocol_limits')
        }}
      </h2>
      <div v-for="key in Object.keys(vehicleStates.get(vehicle_id)?.factsheet?.protocolLimits?? {})"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ key }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.protocolLimits[key]?.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.protocolLimits[key] : '-'
          }}</p>
      </div>
    </li>
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_protocol_features')
        }}
      </h2>
      <div v-for="key in Object.keys(vehicleStates.get(vehicle_id)?.factsheet?.protocolFeatures?? {})"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ key }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.protocolFeatures[key]?.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.protocolFeatures[key] : '-'
          }}</p>
      </div>
    </li>
    <li class="col-span-1 row-span-1 flex-col shadow-sm">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_agv_geometry')
        }}
      </h2>
      <div v-for="key in Object.keys(vehicleStates.get(vehicle_id)?.factsheet?.agvGeometry?? {})"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ key }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.agvGeometry[key]?.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.agvGeometry[key] : '-'
          }}</p>
      </div>
    </li>

    <li class="col-span-1 row-span-1 flex-col shadow-sm pb-4">
      <h2 class="flex items-center color_disabled uppercase text-sm ml-10 mb-2 ">{{
          $t('str_factsheet_load_specification')
        }}
      </h2>
      <div v-for="key in Object.keys(vehicleStates.get(vehicle_id)?.factsheet?.loadSpecification ?? {})"
           class="flex flex-row justify-between mx-10 color_enabled">
        <p class="flex flex-col">
          {{ key }}:
        </p>
        <p>{{
            vehicleStates.get(vehicle_id)?.factsheet?.loadSpecification[key]?.length > 0 ?
                vehicleStates.get(vehicle_id)?.factsheet?.loadSpecification[key] : '-'
          }}</p>
      </div>
    </li>
  </ul>
  <div v-if="showVehicleControlPanel" class="absolute inset-0 bg-white pl-16">
    <button class="absolute top-2 right-2 w-8 h-8 bg-white rounded-full" @click="() => showVehicleControlPanel = false">X</button>
    <iframe
      src="http://192.168.106.191:1880"
      class="w-full h-full"
    ></iframe>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">

import {
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MapPinIcon,
  Squares2X2Icon
} from "@heroicons/vue/24/outline";
import { ExclamationTriangleIcon as ExclamationTriangleIconSolid } from '@heroicons/vue/24/solid';
import {formatTimeString, getVehicleCheckColor} from "@/utils";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import {vehicleStates} from "@/dtos/VehicleState";
import * as ApiManager from "@/network/ApiManager";
import * as AlertManager from "@/datamanagers/AlertsManager";
import {prod_safe_log} from "@/utils";
import {useRoute} from "vue-router";
import {useConfig} from "@/main";
import {publishControlCenterMessage} from "@/network/MqttManager";
import DialogPopup from '@/components/DialogPopup.vue';

export default defineComponent({
  name: "VehiclesOverview",
  data() {
    return {
      // is overwritten by id in created()
      vehicle_id: '0',
      route: useRoute(),
      nodes: new Map<number, any>(),
      vehicleStates,
      showVehicleControlPanel: false,
      hiddenActivationClickCounter: 0,
      hiddenActivationTimeout: null as any
    };
  },
  watch: {
    'route.params.id': function (val, oldVal) {
      this.vehicle_id = String(val);
      this.showVehicleControlPanel = false;
    }
  },
  methods: {
    resetPreassignedOrder() {
      const mqttConfig = useConfig().config.configuration.mqtt
      const topic = `${mqttConfig.emmInterfaceName}/${mqttConfig.emmVersion}/${mqttConfig.manufacturer}/${mqttConfig.ccSubtopic}/preassignedOrder`
      const message = {vehicle_id: this.vehicle_id}
      publishControlCenterMessage(mqttConfig,
          window.location.hostname,
          window.location.protocol,
          topic,
          message
      )
    },
    hiddenActivation() {
      this.hiddenActivationClickCounter += 1;

      if (this.hiddenActivationTimeout) {
        clearTimeout(this.hiddenActivationTimeout);
      }

      if (this.hiddenActivationClickCounter >= 10){
        this.showVehicleControlPanel = true;
      }

      this.hiddenActivationTimeout = setTimeout(() => {
        this.hiddenActivationClickCounter = 0;
      }, 500);
    },
    forceOrderUpdate(vehicleId: string) {
      const mqttConfig = useConfig().config.configuration.mqtt
      const topic = `${mqttConfig.emmInterfaceName}/${mqttConfig.emmVersion}/${mqttConfig.manufacturer}/${mqttConfig.ccSubtopic}/forceUpdateOrder`
      const message = {vehicle_id: this.vehicle_id}
      publishControlCenterMessage(mqttConfig,
          window.location.hostname,
          window.location.protocol,
          topic,
          message
      )
    },
    async confirmForceOrderUpdate(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        //@ts-ignore
        title: this.$t("str_force_order_update"),
        //@ts-ignore
        message: this.$t("str_confirm_force_order_update_description"),
        //@ts-ignore
        okButton: this.$t("str_confirm_force_order_update_yes"),
        okButtonType: 'button',
        //@ts-ignore
        cancelButton: this.$t("str_confirm_force_order_update_cancel"),
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
  },
  mounted() {
  },
  created() {
    this.vehicle_id = String(this.route.params.id)
    ApiManager.getNodes(
        () => {
          // @ts-ignore
          AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_nodes"), this.$t("str_try_again")))
          prod_safe_log('nodes could not be requested')
        },
        (data: any) => {
          this.nodes.clear()
          data.nodes.forEach((node: any) => {
            this.nodes.set(node.nodeId, node)
          })
        });
  },
})
</script>

<style scoped>

</style>
