<template>
  <div class="mx-auto w-full color_background_dark rounded-xl  transition-all
              hover:shadow hover:shadow-gray-500 hover:bg-gray-900 hover:bg-opacity-40
              active:bg-gray-900 active:bg-opacity-70  active:shadow-none active:opacity-70">
    <main class="my-4 relative">
      <div class="absolute m-auto right-0">
        <div class="flex-row">
          <!-- <order-template-menu :order-template-id="savedRoute.orderTemplateId" @editingTemrplate="editingTitle=true"/> -->
          <OptionsDropdown :options="[
            {title: $t('str_order_templates_edit'), handler: () => editingTitle = true},
            {title: $t('str_order_templates_delete'), handler: () => confirm(() => deleteOrderTemplate(savedRoute.orderTemplateId) )},
          ]"></OptionsDropdown>
        </div>
      </div>
      <div class="button2 flex-col px-4 py-4 pr-8" @click="handleOrderClick">
        <p v-show="savedRoute.title && !editingTitle" class="color_enabled uppercase text-xl text-left mb-2">{{
            savedRoute.title
          }}</p>
        <p v-show="editingTitle" class="h-8 w-full my-2"> {{ $t('str_edit_title') }} </p>
        <form @submit="(e:any)=>{e.preventDefault(); editTitle(newTitle)}">
          <input v-show="editingTitle" class="rounded flex-auto my-2 w-full text-gray-900" v-model="newTitle"
                 type="text" name="savedOrderTitle"/>
        </form>
        <ul role="list" class="mx-auto">
          <li v-for="(node, nodeIdx) in savedRoute?.nodes ?? []">
            <div class="relative pb-2">
              <span v-if="nodeIdx !== savedRoute.nodes.length - 1"
                    class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"/>
              <div class="relative flex space-x-3">
                <div>
                <span
                    :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                  <component v-if="savedRoute.nodes.length===1 || nodeIdx === savedRoute.nodes.length - 1"
                             :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                             aria-hidden="true"/>
                  <component v-else :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                </span>
                </div>
                <div class="flex min-w-0 space-x-4 pt-1.5">
                  <div>
                    <p class="color_enabled text-xl text-left">
                      {{ node.nodeId }}: {{ nodes?.get(node.nodeId)?.nodeDescription }}
                    </p>
                  </div>
                </div>
              </div>
              <ul class="pl-16 mt-2 list-disc">
                <li v-for="item in !node.defaultActions ? node.actions : nodes?.get(node.nodeId)?.actions"
                    class="color_disabled">
                  <p class="color_disabled uppercase text-sm text-left">
                    {{ item.action.actionType }}
                  </p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </main>
  </div>
  <DialogPopup ref="dialogPopup"></DialogPopup>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon} from '@heroicons/vue/24/outline'
import OrderTemplateMenu from "@/components/ordertemplate/OrderTemplateMenu.vue";
import OptionsDropdown from '@/components/OptionsDropdown.vue';
</script>

<script lang="ts">
import {defineComponent} from "vue";
import * as ApiManager from "@/network/ApiManager"
import * as AlertManager from "@/datamanagers/AlertsManager";
import {updateOrderTemplate} from "@/network/ApiManager";
import DialogPopup from '@/components/DialogPopup.vue';

export default defineComponent({
  name: "QuickRouteItem",
  emits: ['orderRequestSelected'], 
  data() {
    return {
      editingTitle: false,
      newTitle: ''
    }
  },
  props: {
    savedRoute:
        {
          //  {nodes: [{nodeId: String}]}
          type: null,
          default: null
        },
    nodes: {
      type: Map,
      default: []
    }
  },
  watch: {
    savedRoute(newV, oldV) {
      console.log("Saved route", newV)
    },
  },
  computed: {},
  methods: {
    async confirm(callback: Function) {
      let confirmed = await (this.$refs.dialogPopup as typeof DialogPopup).show({
        //@ts-ignore
        title: this.$t("str_confirm_order_template_deletion_title"),
        //@ts-ignore
        message: this.$t("str_confirm_order_template_deletion_message"),
        //@ts-ignore
        okButton: this.$t("str_confirm_order_template_deletion_btn_delete"),
        //@ts-ignore
        cancelButton: this.$t("str_confirm_order_template_deletion_btn_cancel"),
      });
      if (confirmed) {
        callback();
      } else {
        console.log('Cancelled');
      }
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    },
    editTitle(newTitle: string) {
      console.log("Submitted new title", newTitle)
      ApiManager.updateOrderTemplate(this.savedRoute.orderTemplateId, newTitle, () => {
      }, () => {
        this.editingTitle = false;
        console.log('Edited order template ', this.savedRoute.orderTemplateId)
        this.savedRoute.title = newTitle;
      })
    },
    handleOrderClick(event: MouseEvent) {
      if((event.target as HTMLInputElement).name !== "savedOrderTitle"){
        this.$emit('orderRequestSelected', this.savedRoute?.nodes)
      }
    },
    deleteOrderTemplate(orderTemplateId: string) {
      ApiManager.deleteOrderTemplate(orderTemplateId, () => {
      }, () => {
        console.log('Deleted order template ', orderTemplateId)
      })
    },
  }
})


</script>

<style scoped>
</style>
