import {reactive} from "vue";
import {getLocale} from "@/datamanagers/StorageManager";

export const liveViewEditorState = reactive({
    editMode: false,
    editingTrajectory: false,
    capturingEndNode: false,
    newOrderOpen: false,
    editedConnectedComponent: null as {id: (number | string | null), edges: number[]} | null,
    locale: getLocale()
});
