<template>
  <div class="w-full mb-2">
    <Listbox as="div" v-model="selectedElement">
      <div class="relative w-full">
        <ListboxButton
            class="w-full h-6 cursor-default rounded-sm border border-gray-300 bg-white pl-3 text-left shadow-sm">
          <span class="block truncate leading-none">{{ selectedElement ?? prompt }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
          <ListboxOptions
              class="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <li v-if="isFilterable" class="p-2 sticky l-0 r-0 bg-white" style="top:-4px; z-index: 1;">
              <input v-model="query" type="text" class="block w-full border-2 rounded-sm p-1" style="padding-right: 60px;">
              <span v-if="isFilterable && query.length" class="absolute right-5 top-4">
                <button v-on:click="clearSelection">Reset</button>
              </span>
            </li>
            <ListboxOption as="template" v-for="(item, i) in filteredElements().value" :key="i" :value="item"
                           v-slot="{ active, selected }">
              <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                  {{ item }}
                </span>
                <span v-if="selected"
                      :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup lang="ts">
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue'</script>

<script lang="ts">
import { ref, computed, defineComponent } from "vue";

export default defineComponent({
  name: "Dropdown",
  data() {
    return {
      selectedElement: this.selectedItem || null,
      selectedIndex: -1,
      query: '',
      filteredElements: () => computed(() => {
        return (this.query.toString() === '') ? this.elemList : this.elemList.filter((element: any) => {
          return element.toString().toLowerCase().includes(this.query.toString().toLowerCase());
        });
      }),
    }
  },
  props: {
    elemList: {
      type: Array,
      default: false
    },
    defaultSelectionId: {
      type: null,
      default: null
    },
    prompt: {
      type: null,
      default: null
    },
    isFilterable:{
      type: Boolean,
      default: false
    },
    selectedItem: {
      type: null,
      default: null
    }
  },
  watch: {
    elemList(newVal, oldVal) {
      console.log("updated dropdown elements", newVal)
      if (this.selectedIndex > -1)
        this.selectedElement = newVal[this.selectedIndex]
    },
    selectedElement(newEl: string, oldEl: string) {
      this.query=this.isFilterable && !!newEl ? newEl : '';
      this.$emit('selectedEl', newEl);
      this.selectedIndex = this.elemList.indexOf(newEl)
    },
    selectedItem(newVal) {
      this.selectedIndex = this.elemList.indexOf(newVal);
      this.selectedElement = newVal;
    }
  },
  methods: {
    clearSelection() {
      this.selectedElement = null;
      this.selectedIndex = -1;
      this.query='';
    }
  },
  created() {
    if (!!this.defaultSelectionId){
      this.selectedElement = this.defaultSelectionId;
    }
  }
})

</script>

<style scoped>

</style>
