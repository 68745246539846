<template>
  <TransitionRoot appear :show="true" as="template">
    <Dialog as="div" @close="closeModal(false)" class="relative z-10">
      <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25"/>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
            <DialogPanel
                class="w-full max-w-md transform overflow-visible rounded-2xl color_background_light p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 color_enabled">
                {{ $t('str_confirm_order_from_template') }}
              </DialogTitle>
              <div class="my-2">
                <ul>
                  <li v-for="(node, nodeIdx) in savedRoute?.nodes ?? []">
                    <div class="relative pb-2">
                    <span v-if="nodeIdx !== savedRoute?.nodes?.length - 1"
                          class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"/>
                      <div class="relative flex space-x-3">
                        <div>
                    <span :class="['color_background_light', 'h-8 w-8 rounded-full flex items-center justify-center']">
                      <component v-if="savedRoute?.nodes?.length===1 || nodeIdx === savedRoute?.nodes?.length - 1"
                                 :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                                 aria-hidden="true"/>
                      <component v-else :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                    </span>
                        </div>
                        <div class="flex min-w-0 space-x-4 pt-1.5">
                          <div>
                            <p class="color_enabled text-xl text-left">
                              {{ node.nodeId }}: {{ nodes?.get(node.nodeId)?.nodeDescription }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <ul class="pl-16 mt-2 list-disc">
                        <li v-for="item in !node.defaultActions? node.actions : nodes?.get(node.nodeId)?.actions"
                            class="color_disabled">
                          <p class="color_disabled uppercase text-sm text-left">
                            {{ item.action.actionType }}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>

              <VehicleDropdown class="mb-2" :vehicles="vehicles" :label="$t('str_vehicle')"
                               @vehicleSelected="setVehicleId"/>
              <li class="flex flex-row flex-grow mb-2">
                <p class="color_enabled items-center mr-6">{{ $t("str_order_request_priority") }}: </p>
                <Dropdown class="w-full" :elem-list="priorityValues"
                          :prompt="priorityValues[orderRequestPriority]"
                          @selectedEl="(el:string)=>orderRequestPriority=priorityValues.indexOf(el)"/>
              </li>

              <div class="mt-4 flex justify-end">
                <button
                    type="button"
                    class="flex justify-center rounded-md border px-4 py-2 text-sm font-medium button "
                    @click="closeModal(true)"
                >
                  {{ $t('str_submit_order') }}
                </button>

                <button
                    type="button"
                    class="flex justify-center rounded-md border mx-4 px-4 py-2 text-sm font-medium button2 "
                    @click="closeModal(false)"
                >
                  {{ $t('str_cancel') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon} from '@heroicons/vue/24/outline'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,} from '@headlessui/vue'
import VehicleDropdown from "@/components/VehicleDropdown.vue";
import Dropdown from "@/components/Dropdown.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import {getPriorityStrings} from "@/utils";

export default defineComponent({
  name: "OrderConfirmationPopup",
  data() {
    return {
      vehicleId: "",
      orderRequestPriority: 1, //high priority
      priorityValues: getPriorityStrings(this)
    }
  },
  props: {
    savedRoute:
        {
          //  {nodes: [{nodeId: String}]}
          type: null,
          default: null
        },
    vehicles:
        {
          type: Map,
          default: []
        },
    nodes: {
      type: Map,
      default: []
    }
  },
  watch: {
    savedRoute(newV, oldV) {
      console.log("Saved route", newV)
    },
  },
  computed: {},
  methods: {
    closeModal(submitOrder: boolean) {
      this.$emit('orderConfirmed', submitOrder, {
        nodes: this.savedRoute?.nodes ?? [], vehicleId: this.vehicleId, priority: this.orderRequestPriority
      })
    },
    setVehicleId(selectedId: string) {
      this.vehicleId = selectedId
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    }
  }
})


</script>

<style scoped>
</style>
