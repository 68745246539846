import {VehicleState} from "@/dtos/VehicleState";

function add_lead_zero(num: any) {
    return String(num).padStart(2, '0')
}

function seconds_to_hours(sec: number) {
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor((sec - hours * 3600) / 60)
    const seconds = sec - minutes * 60 - hours * 3600

    return `${add_lead_zero(hours)}:${add_lead_zero(minutes)}:${add_lead_zero(seconds)}`
}

function zip(a: Array<any>, b: Array<any>) {
    var array = new Array<Array<any>>()
    for (let i = 0; i < a.length; i++) {
        array[i] = [a[i], b[i]]
    }
    return array
}

function round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

function prod_safe_log(...str: any) {
    const production = process.env.NODE_ENV === 'production'
    if (!production) {
        console.log(...str)
    }
}

function formatTimeString(timestamp: number, milisec = false) {
    let timestring = '-'
    if (!isNaN(timestamp)) {
        const date = new Date(0)
        if (Number(timestamp) && !milisec) date.setUTCSeconds(Number(timestamp))
        else if (milisec) date.setUTCMilliseconds(timestamp)
        timestring = date.toLocaleString('de-DE')
    }
    return timestring
}

function sqr(x: number) { return x * x }
function dist2(v: { x: number, y: number }, w: { x: number, y: number }) { return sqr(v.x - w.x) + sqr(v.y - w.y) }
function distToSegmentSquared(p: { x: number, y: number }, v: { x: number, y: number }, w: { x: number, y: number }) {
    // i.e. |w-v|^2 -  avoid a sqrt
    var l2 = dist2(v, w);
    // v == w case
    if (l2 == 0) return dist2(p, v);
    // Consider the line extending the segment, parameterized as v + t (w - v).
    // We find projection of point p onto the line. 
    // It falls where t = [(p-v) . (w-v)] / |w-v|^2
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    // We clamp t from [0,1] to handle points outside the segment vw.
    t = Math.max(0, Math.min(1, t));
    // Projection falls on the segment
    const projection = {
        x: v.x + t * (w.x - v.x),
        y: v.y + t * (w.y - v.y)
    }
    return dist2(p, projection);
}

function distToSegment(p: { x: number, y: number }, v: { x: number, y: number }, w: { x: number, y: number }) {
    return Math.sqrt(distToSegmentSquared(p, v, w));
}

function arrToXY(arr: any []) {
    if (arr.length == 2) {
        return {x: arr[0], y: arr[1], theta: null}
    } else if (arr.length == 3) {
        return {x: arr[0], y: arr[1], theta: arr[2]}
    } else throw new EvalError("Excpected array of length 2 or 3, got " + arr.length)
}

function getVehicleCheckColor(vehicle_state: any) {
    // @ts-ignore
    if (!vehicle_state.connected)
        return 'text-transparent'
    // @ts-ignore
    else if (vehicle_state.operatingMode == VehicleState.OPERATING_MODE_AUTOMATIC)
        return 'color_icon_online_auto'
    else
        return 'color_icon_online_manual'
}

function actionArrayChanged(oldActionArray: any, newActionArray: any) {
    var dataChanged = false
    if (oldActionArray?.length === newActionArray?.length) {
        for (var i = 0; i < newActionArray?.length; i++) {
            const old_action = oldActionArray.at(i)
            const new_action = newActionArray.at(i)
            if (old_action?.action?.actionId != new_action?.action.actionId) {
                dataChanged = true
                console.log("changed actions id", old_action?.action?.actionId, new_action?.actionId)
                break
            }
            if (old_action?.actionParameters?.length != new_action?.actionParameters.length) {
                dataChanged = true
                console.log("changed actionParameters length")
                break
            }
            for (var j = 0; j < old_action.actionParameters.length; j++) {
                if (old_action?.actionParameters[j].key != new_action?.actionParameters[j].key) {
                    console.log("changed actionParameters key")
                    dataChanged = true
                    break
                }
                if (old_action?.actionParameters[j].value != new_action?.actionParameters[j].value) {
                    console.log("changed actionParameters value")
                    dataChanged = true
                    break
                }
            }
        }
    } else {
        console.log("changed actions length")
        dataChanged = true
    }
    return dataChanged
}

function getPriorityStrings(context: any) {
    return [
        "str_order_priority_values[0]",
        "str_order_priority_values[1]",
        "str_order_priority_values[2]",
        "str_order_priority_values[3]",
        "str_order_priority_values[4]"].map(el => context.$t(el))
}

export {
    seconds_to_hours,
    zip,
    round,
    prod_safe_log,
    formatTimeString,
    distToSegment,
    arrToXY,
    dist2,
    getVehicleCheckColor,
    actionArrayChanged,
    getPriorityStrings,
}
