/**
 * The LiveViewHelper helps calculating, converting values that are needed for building the live view
 * directly consumed by LiveView.vue
 * The idea is that complex views don't need to know how to transform data, instead they should use corresponding Adapter.
 * This way formatting code can be reused in other views and the structure of data is hidden from the view.
 */
import * as ApiManager from "@/network/ApiManager";
import {TrajectoryData} from "@/helpers/TrajectoryHelper";

export class CanvasHelper {
    private ctx: CanvasRenderingContext2D;
    private reverseTransform: DOMMatrix;

    constructor(context: any) {
        this.ctx = context
        this.reverseTransform = this.ctx.getTransform().inverse()
    }
    initContext(map:any, canvasSize:number[]){
        const canvas_width = canvasSize[0]
        const canvas_height = canvasSize[1]
        this.ctx.drawImage(map, 0, 0, canvas_width, canvas_height);
    }
    rebaseContext_(zoom_scale: number, map:any, bosh_map:any, canvasSize:number[], mapCenter:number[], mapConfig:any) {
        const canvas_width = canvasSize[0]
        const canvas_height = canvasSize[1]
        var scale = map.naturalHeight / canvas_height;
        this.ctx.clearRect(0, 0, canvas_width, canvas_height);
        this.ctx.scale(zoom_scale, zoom_scale)
        this.ctx.drawImage(map, 0, 0, canvas_width, canvas_height);
        this.ctx.scale(mapConfig.map_scale[0], mapConfig.map_scale[1]); //scale map coordinate
        this.ctx.rotate((Math.PI / 180) * mapConfig.map_rotate); //rotate map coordinate
        this.ctx.scale(1, mapConfig.scanner_reversion_factor); // flip map coordinate
        this.ctx.translate(mapConfig.map_translate[0] * (canvas_height / 500), mapConfig.map_translate[1] * (canvas_height / 500)); // translate map corrdinate
        this.ctx.drawImage(bosh_map, 0, 0, bosh_map.naturalWidth / scale, bosh_map.naturalHeight / scale);//bosch map over the sim map
        // translate to map center1
        this.ctx.translate(mapCenter[0] * (bosh_map.naturalWidth / scale), mapCenter[1] * (bosh_map.naturalHeight / scale));

        // Saves transform so that we can use it without prior rebaseContext
        this.reverseTransform = this.ctx.getTransform().inverse()
    }

    reverseTransformForPoint(p: { x: number, y: number, theta: number | null }) {
        let t = this.reverseTransform
        p = {x: p.x * t.a + p.y * t.c + t.e, y: p.x * t.b + p.y * t.d + t.f, theta: p.theta}
        return p
    }

    drawLine(points: any, f: number = 0.3, t = 1, color: string = '#000000', dash: number = 1, zoom: number = 1, opacity: number = 1, baseLineWidth = 1) {
        this.ctx.setLineDash([dash]);
        this.ctx.lineWidth = baseLineWidth / zoom;
        this.ctx.strokeStyle = color
        CanvasHelper.drawLine(this.ctx, points, f, t, opacity)
    }

    drawPoint(p: { x: number, y: number }, color: string = '#000000', zoom: number = 1) {
        this.ctx.fillStyle = color
        CanvasHelper.drawPoint(this.ctx, p, color, zoom)
    }

    drawText(p: { x: number, y: number }, txt: string, mapRotation: number, color: string = '#000000',
             flip: boolean = false, zoom: number = 1) {
        const size = (10 / zoom).toFixed(0);
        this.ctx.font = size + 'px roboto';
        this.ctx.fillStyle = color;
        CanvasHelper.drawText(this.ctx, p, txt, mapRotation, color, flip, zoom);
    }

    drawRectangle(p: { x: number, y: number, theta: number }, size: {w: number, l: number}, mapRotation: number, color: string = '#000000', scale = 1) {
        this.ctx.fillStyle = color;
        CanvasHelper.drawRectangle(this.ctx, p, size, mapRotation, color, scale);
    }

    drawDirectionArrow(p: { x: number, y: number, theta: number }, size: {w: number, l: number}, mapRotation: number, color: string = '#000000', scale = 1) {
        this.ctx.fillStyle = color;
        CanvasHelper.drawDirectionArrow(this.ctx, p, size, mapRotation, color, scale);
    }

    drawArrow(from: { x: number, y: number }, to: { x: number, y: number }, color: string, zoom: number = 1, opacity: number = 1, baseLineWidth = 1) {
        this.ctx.save();
        this.ctx.globalAlpha = opacity;
        this.ctx.setLineDash([0]);
        this.ctx.lineWidth = baseLineWidth / zoom;
        this.ctx.strokeStyle = color
        this.ctx.beginPath();
        CanvasHelper.canvas_arrow(this.ctx, from, to, zoom)
        this.ctx.stroke();
        this.ctx.restore();
    }

    static canvas_arrow(context: any, from: { x: number, y: number }, to: { x: number, y: number }, zoom: number = 1) {
        var headlen = (8 / zoom); // length of head in pixels
        var dx = to.x - from.x;
        var dy = to.y - from.y;
        var angle = Math.atan2(dy, dx);
        context.moveTo(from.x, from.y);
        context.lineTo(to.x, to.y);
        context.lineTo(to.x - headlen * Math.cos(angle - Math.PI / 6), to.y - headlen * Math.sin(angle - Math.PI / 6));
        context.moveTo(to.x, to.y);
        context.lineTo(to.x - headlen * Math.cos(angle + Math.PI / 6), to.y - headlen * Math.sin(angle + Math.PI / 6));
    }

    static drawLine(ctx: any, points: any, f: number = 0.3, t = 1, opacity: number = 1) {
        if (points.length == 0)
            return 0

        ctx.save();
        ctx.globalAlpha = opacity;

        //f = 0, will be straight line
        //t suppose to be 1, but changing the value can control the smoothness too
        if (typeof (f) == 'undefined') f = 0.3;
        if (typeof (t) == 'undefined') t = 0.6;

        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);

        var m = 0;
        var dx1 = 0;
        var dy1 = 0;

        var preP = points[0];
        for (var i = 1; i < points.length; i++) {
            var curP = points[i];
            ctx.lineTo(curP.x, curP.y)
            preP = curP;
        }
        ctx.stroke();
        ctx.restore();
    }

    static  gradient(a: any, b: any) {
        return (b.y - a.y) / (b.x - a.x);
    }

    static drawPoint(ctx: any, p: { x: number, y: number }, color: string = '#000000', zoom: number = 1) {
        ctx.save()
        ctx.beginPath();
        ctx.arc(p.x, p.y, Number((3 / zoom).toFixed(0)), 0, 2 * Math.PI);
        ctx.fill();
        ctx.restore()
    }

    static drawText(ctx: any, p: { x: number, y: number }, txt: string, mapRotation: number, color: string = '#000000',
                    flip: boolean = false, zoom: number = 1) {
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate((Math.PI / 180) * (-1 * mapRotation)); //rotate text
        let offsetX = 0;
        if (flip) offsetX = ctx.measureText(txt).width + 20;
        ctx.strokeStyle = 'white'
        ctx.strokeText(txt, Number(((10 - offsetX) / zoom).toFixed(0)), Number((10 / zoom).toFixed()))
        ctx.fillText(txt, Number(((10 - offsetX) / zoom).toFixed(0)), Number((10 / zoom).toFixed()));
        ctx.restore();
    }

    static drawRectangle(ctx: any, p: { x: number, y: number, theta: number }, size: { w: number, l: number}, mapRotation: number, color: string = '#000000', zoom: number = 1) {
        ctx.save();
        ctx.translate(p.x, p.y);
        ctx.rotate(Math.PI/2 - p.theta);//rotate to match direction (it is already taking into account the map rotation)
        ctx.fillRect(-size.w/2, -size.l/2, size.w, size.l);
        ctx.rotate(Math.PI/2 + p.theta);//rotate back from direction
        ctx.translate(-p.x, -p.y)
        ctx.restore()
    }

    static drawDirectionArrow(ctx: any, p: { x: number, y: number, theta: number }, size: { w: number, l: number}, mapRotation: number, color: string = '#000000', zoom: number = 1) {
      ctx.save();
      ctx.translate(p.x, p.y);
      ctx.rotate(-Math.PI/2 - p.theta);//rotate to match direction (it is already taking into account the map rotation)
      ctx.beginPath();
      ctx.moveTo(0, size.l/3);
      ctx.lineTo(-size.w/3, -size.l/3);
      ctx.lineTo(0, -size.l/6);
      ctx.lineTo(size.w/3, -size.l/3);
      ctx.closePath();
      ctx.fill();
      ctx.rotate(-Math.PI/2 + p.theta);//rotate back from direction
      ctx.translate(-p.x, -p.y)
      ctx.restore()
  }

}

class LiveViewHelper {
    mapConfig: any
    canvas_height: any;
    canvas_width: any;
    private bosch_map: any;
    private map: any;
    private mapCenter: number[] = [0, 0];
    canvasHelper: CanvasHelper = null as unknown as CanvasHelper

    constructor(mapConfig: any) {
        this.mapConfig = mapConfig
    }
    init(canvas_height: any, canvas_width: any, canvasHelper: CanvasHelper) {
        this.canvas_height = canvas_height;
        this.canvas_width = canvas_width;
        this.map = this.loadMap(this.mapConfig.map_img)
        this.bosch_map = this.loadMap(this.mapConfig.bosch_map)
        this.mapCenter = this.calculateMapCenter()
        this.canvasHelper = canvasHelper
    }
    // reload mapconfig and reinit
    switchmap(mapConfig: any, onMapsLoaded: any) {
        this.mapConfig = mapConfig
        this.map = this.loadMap(this.mapConfig.map_img)
        this.map.addEventListener('load', (event: any) => {
            this.bosch_map = this.loadMap(this.mapConfig.bosch_map)
            this.bosch_map.addEventListener('load', (event: any) => {
                onMapsLoaded()
            }, false);
        }, false);
        this.mapCenter = this.calculateMapCenter()
    }
// Load map from path and return
    loadMap(img_path: any) {
        var mapObject = new Image();
        mapObject.src = `api/2.0/map_configs/map_image/${img_path}`;
        return mapObject
    }

// Calculate map center on canvas from map data
    calculateMapCenter() {
        var map_center: number[];

        const configuredCenterX = (-1) * this.mapConfig.map_center[0] / this.mapConfig.map_real_size[0];
        const configuredCenterY = (-1) * this.mapConfig.map_center[1] / this.mapConfig.map_real_size[1];

        if (this.mapConfig.scanner_reversion_factor == 1) {
            map_center = [configuredCenterX, 1 - configuredCenterY];
        } else {
            map_center = [1 - configuredCenterX, 1 - configuredCenterY];
        }
        return map_center;
    }

    realWorldToCanvasCoord(pos: { x: number, y: number, theta: number }) {
        if (pos?.x == undefined ?? pos.y == undefined ){
            console.warn("Baaaad poitn ", pos)
            return null
        } 
        let relative_position = [pos.x * (this.mapConfig["scanner_reversion_factor"]) / this.mapConfig["map_real_size"][0],
            pos.y * (-1) / this.mapConfig["map_real_size"][1],
            pos.theta];

        const scale = this.map.naturalHeight / this.canvas_height;
        relative_position = [
            relative_position[0] + relative_position[0] * (this.bosch_map.naturalWidth / scale),
            relative_position[1] + relative_position[1] * (this.bosch_map.naturalHeight / scale),
            relative_position[2]
        ]

        return {x: relative_position[0], y: relative_position[1], theta: relative_position[2]}
    }

    // rotates point around origin by angle
    rotatePoint(p: number[], angle: number) {
        // const rotationMatrix = [[Math.cos(angle), Math.sin(angle)], [ -Math.sin(angle), Math.cos(angle)]]
        return [[p[0] * Math.cos(angle) - p[1] * Math.sin(angle)], [p[0] * Math.sin(angle) + p[1] * Math.cos(angle)]]
    }

    canvasToRealWorldCoordinates(p: { x: number, y: number, theta: number | null }) {
        const scale = this.map.naturalHeight / this.canvas_height;
        const img_bosch_map = this.bosch_map
        var pp = this.reverseTransformForPoint(p)

        // relative_position
        pp = {
            x: pp.x * scale / (scale + img_bosch_map.naturalWidth),
            y: pp.y * scale / (scale + img_bosch_map.naturalHeight),
            theta: pp.theta
        }

        // final_position
        pp = {
            x: pp.x * this.mapConfig["map_real_size"][0] / this.mapConfig["scanner_reversion_factor"],
            y: pp.y * this.mapConfig["map_real_size"][1] / (-1),
            theta: pp.theta
        }
        return pp
    }

    reverseTransformForPoint(p: { x: number, y: number, theta: number | null }) {
        return this.canvasHelper.reverseTransformForPoint(p)
    }

    rebaseContext(zoom_scale: number){
        this.canvasHelper.rebaseContext_(zoom_scale, this.map, this.bosch_map,
            [this.canvas_width, this.canvas_height], this.mapCenter, this.mapConfig)
    }

    initBaseMap() {
        var img = this.map
        var scale = img.naturalHeight / this.canvas_height;
        this.canvas_width = img.naturalWidth / scale
        this.canvasHelper.initContext(img, [this.canvas_width, this.canvas_height])
    }

    // facade for canvas helper
    drawLine(points: any, f: number = 0.3, t = 1, color: string = '#000000', dash: number = 0, zoom: number = 1, opacity: number = 1,  baseLineWidth = 1) {
        this.canvasHelper.drawLine(points, f, t, color, dash, zoom, opacity, baseLineWidth)
    }

    drawPoint(p: { x: number, y: number }, color: string = '#000000', zoom: number = 1) {
        this.canvasHelper.drawPoint(p, color, zoom)
    }

    drawText(p: { x: number, y: number }, txt: string, mapRotation: number, color: string = '#000000',
             flip: boolean = false, zoom: number = 1) {
        this.canvasHelper.drawText(p, txt, mapRotation, color, flip, zoom)
    }

    drawRectangle(p: { x: number, y: number, theta: number }, size: {w: number, l: number}, mapRotation: number, color: string = `rgba(32, 45, 21, 1)`, scale = 1) {
        this.canvasHelper.drawRectangle(p, size, mapRotation, color, scale);
    }

    drawDirectionArrow( p: {x: number, y: number, theta: number}, size: {w: number, l: number}, mapRotation: number, color: string = `rgba(255, 165, 0, 1)`, scale = 1){
      this.canvasHelper.drawDirectionArrow(p, size, mapRotation, color, scale);
    }

    drawArrow(from: { x: number, y: number }, to: { x: number, y: number }, color: string = '#000000', zoom: number = 1, opacity: number = 1, baseLineWidth = 1) {
        this.canvasHelper.drawArrow(from, to, color, zoom, opacity, baseLineWidth)
    }

    static isMouseOver(p: { x: number, y: number }, points: any[], thresholdRadius: number) {
        var ids = Array()
        points.forEach((el: { x: number, y: number }, index: number) => {
                if (((el.x - p.x) ** 2 + (el.y - p.y) ** 2) < thresholdRadius ** 2) {
                    ids.push(index)
                }
                });
        return ids
    }

    static createCurveFromApi(curve: any){
        if (curve == null) return null
        var nurbs = require('nurbs');
        var curve_nurbs = null;
        const newPoints = curve.controlPoints.map((p:any)=> [p.x, p.y])
        // console.log("newpoints: ", newPoints, "pldPoint: ", curve.controlPoints)
        if (curve.degree>=1) {
            curve_nurbs = nurbs({
                points: newPoints,
                degree: curve.degree,
                knots: curve.knotVector
            });
        }
        return curve_nurbs
    }

    static CalculateTrajectoryForNewNodePosition(trajectory:any, edge:any){
      // TODO remove duplication of the fitTrajectory function for this static method.
      const startPoint =edge.trajectory.inputPoints.at(0)
      const endPoint =edge.trajectory.inputPoints.at(-1)
      let rawTrajectory = trajectory.getRawTrajectory()
      if (rawTrajectory?.length > 1)
          ApiManager.fitRawTrajectory(rawTrajectory, startPoint, endPoint, () => { }, (curveApi: any) => {
              let curve = LiveViewHelper.createCurveFromApi(curveApi)
              edge.trajectory.addCurve(curve)
              edge.trajectory.nurbsCurveVda = curveApi
              edge.length = curveApi.length
          })
      else trajectory.removeCurve();
    }

    fitTrajectory = function async(trajectory:any, edge:any){
        const startPoint = edge.trajectory.inputPoints.at(0);
        const endPoint = edge.trajectory.inputPoints.at(-1);
        const rawTrajectory = trajectory.getRawTrajectory();
        const apiCallPromise = new Promise((resolve, reject) => {
          if (rawTrajectory?.length > 1){
            ApiManager.fitRawTrajectory(rawTrajectory, startPoint, endPoint, (err: any) => { reject(err) }, (curveApi: any) => {
                const curve = LiveViewHelper.createCurveFromApi(curveApi)
                edge.trajectory.addCurve(curve)
                edge.trajectory.nurbsCurveVda = curveApi
                edge.length = curveApi.length
                resolve(edge)
            });
          } else {
            trajectory.removeCurve();
            resolve(edge)
          }
        });
        return apiCallPromise;
    }
    findAdjacentEdge(edge:any, allEdges:any[]){
        const startEdges :any[]= []
        const endEdges :any[]= []
        allEdges.forEach(value => {
            const connectedNodes =[value.startNodeId, value.endNodeId]
            if (value.edgeId == edge.edgeId) return
            if (connectedNodes.findIndex(value1 => edge.startNodeId==value1)>=0) startEdges.push(value)
            if (connectedNodes.findIndex(value1 => edge.endNodeId==value1)>=0) endEdges.push(value)
        })
        return {start:startEdges, end:endEdges}
    }
    smoothEdge(edge:any, allEdges:any[]){
        const connectedEdges = this.findAdjacentEdge(edge, allEdges)
        const trajectory: TrajectoryData = edge.trajectory
        trajectory.resetAuxPoints()
        let p = this.findPointToAdd(connectedEdges.start, edge.startNodeId, 40)
        trajectory.addAuxPoint(p, true)
        p = this.findPointToAdd(connectedEdges.start, edge.endNodeId, 30)
        trajectory.addAuxPoint(p, true)
        p = this.findPointToAdd(connectedEdges.end, edge.endNodeId, 30)
        trajectory.addAuxPoint(p, false)
        p = this.findPointToAdd(connectedEdges.end, edge.endNodeId, 40)
        trajectory.addAuxPoint(p, false)
        this.fitTrajectory(trajectory, edge)
        connectedEdges.start.forEach((edge1:any)=>{
            this.fitTrajectory(edge1.trajectory, edge1)
        })
        connectedEdges.end.forEach((edge1:any)=>{
            const startP =edge1.trajectory.inputPoints.at(0)
            const endP =edge1.trajectory.inputPoints.at(-1)
            this.fitTrajectory(edge1.trajectory, edge1)
        })
    }
    findPointToAdd(connectedEdges:any[], nodeId:string, distance:number){
        var newAnchor: {x:number, y:number}[]= []
        connectedEdges.forEach(value => {
            distance = Math.min(Math.round(value.trajectory.fittedPoints.length/2), distance)
            const sstartP = value.trajectory.fittedPoints.at(distance)
            const eendP = value.trajectory.fittedPoints.at(value.trajectory.fittedPoints.length - distance)
            if (nodeId == value.startNodeId && sstartP!=null) newAnchor.push(sstartP)
            else if (nodeId == value.endNodeId && eendP!=null) newAnchor.push(eendP)
        })
        if (newAnchor.length > 1) {
            const sum = newAnchor.reduce((a: { x: number, y: number }, b) => {
                return {x: a.x + b.x, y: a.y + b.y}
            }, {x: 0, y: 0});
            const avg = ({x: sum.x / newAnchor.length || 0, y: sum.y / newAnchor.length || 0})
            newAnchor = [avg]
        }
        return newAnchor[0]
    }

    getConnectedEdges(edges: [any], nodeId: string) {
        return edges.filter((el: any) => el.startNodeId == nodeId || el.endNodeId == nodeId)
    }

    drawSafetyBox(box: any, color: string, scale: number) {
        const p = []
        for (const corner of box.corners) {
            p.push({
                x: corner[0],
                y: corner[1], theta: 1
            })
        }
        if (p.length > 0) {
            p.push(p[0])
            const mapped_p = p.map((value: any) => this.realWorldToCanvasCoord(value))
            this.drawLine(mapped_p, undefined, undefined, color, 0, scale)
        }
    }
}

export {LiveViewHelper}
