<template>
  <div class="mx-auto ">
    <main class="mx-6 my-4 flex-col">
      <div class="flex flex-row  py-4 ">
        <p class="color_disabled -ml-2 text-sm uppercase">{{ $t("str_new_order") }}</p>
        <div class="flex flex-grow"/>
        <button type="button" @click="closeModal"
                class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 relative -right-2.5">
          <XMarkIcon class="h-7 w-7" aria-hidden="true"/>
        </button>
      </div>
      <ul role="list" class="mx-auto">
        <li class="flex flex-row flex-grow mb-2">
          <VehicleDropdown :vehicles="vehicles" :label="$t('str_vehicle')"
                           @vehicleSelected="setVehicleId"/>
        </li>
        <li class="flex flex-row flex-grow mb-2">
          <p class="color_enabled items-center mr-6">{{ $t("str_order_request_priority") }}: </p>
          <Dropdown :elem-list="priorityValues"
                    :prompt="priorityValues[orderRequestPriority]"
                    @selectedEl="(el:string)=>orderRequestPriority=priorityValues.indexOf(el)"/>
        </li>
        <li v-for="(node, eventIdx) in nodeSequence">
          <div class="relative pb-2 ml-2">
            <span v-if="eventIdx !== nodeSequence.length - 1"
                  class="absolute top-6 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"/>
            <div class="relative flex space-x-3">
              <div>
                <span
                :class="['color_background_light', 'h-5 w-5 rounded-full flex items-center justify-center relative left-1.5 top-5']">
                  <component v-if="nodeSequence.length===1 || eventIdx === nodeSequence.length - 1"
                  :is="CheckCircleIcon" class="h-5 w-5 color_disabled"
                  aria-hidden="true"/>
                  <component v-else :is="ArrowDownCircleIcon" class="h-5 w-5 color_disabled" aria-hidden="true"/>
                </span>
              </div>
              <div v-if="nodeSequence.length !== 1" class="color_enabled absolute -left-8 top-2">
                <div class="-ml-0.5 h-6 w-6">
                  <button type="button" @click="shiftNodeInTheList(eventIdx, -1)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <ChevronUpIcon v-if="eventIdx !== 0" class="h-6 w-6"/>
                  </button>
                </div>
                <div class="-ml-0.5 h-6 w-6">
                  <button type="button" @click="shiftNodeInTheList(eventIdx, 1)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <ChevronDownIcon class="h-6 w-6" v-if="eventIdx !== nodeSequence.length - 1"/>
                  </button>
                </div>
              </div>
              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div class="flex justify-between w-full color_enabled">
                  <p class="">
                    {{ node.nodeId }}: {{ node.nodeDescription }}
                  </p>
                  <div class="relative -right-3 -mb-2 text-gray-400 hover:text-gray-500">
                  <button type="button" @click="removeNodeFromSequence(eventIdx)"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none">
                    <XMarkIcon class="-ml-0.5 h-6 w-6" aria-hidden="true" />
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-7">
              <input type="checkbox" id="default_actions" class="mx-2"
                     :checked="!nodeSequence.at(eventIdx).defaultActions"
                     @click="nodeSequence.at(eventIdx).defaultActions = !nodeSequence.at(eventIdx).defaultActions;">
              <label class="color_enabled"
                     @click="nodeSequence.at(eventIdx).defaultActions = !nodeSequence.at(eventIdx).defaultActions">
                {{ $t("str_custom_actions") }}
              </label>
            </div>
            <div v-show="!nodeSequence.at(eventIdx).defaultActions" class="ml-10 mt-2">
              <ActionsView :scope="0"
                           :compactView="Number(1)"
                           :savedActions="nodeSequence.at(eventIdx).actions"
                           @resultActions="(newActions) => setActions(eventIdx, newActions)"/>
            </div>
          </div>
        </li>
        <li class="flex flex-row flex-grow my-4">
          <button @click="submitOrder" class="mx-8 border w-full rounded-md py-1 button">
            {{ $t("str_submit_order") }}
          </button>
        </li>
        <li class="flex flex-row flex-grow my-4">
          <button @click="saveQuickRoute" class="mx-8 border w-full rounded-md py-1 button2">
            {{ $t("str_save_quick_route") }}
          </button>
        </li>
      </ul>

    </main>
  </div>
</template>

<script setup lang="ts">
import {ArrowDownCircleIcon, CheckCircleIcon} from '@heroicons/vue/24/outline';
import {TrashIcon, XMarkIcon, ChevronUpIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import VehicleDropdown from "@/components/VehicleDropdown.vue";
import ActionsView from "@/components/ActionsView.vue";
import Dropdown from "@/components/Dropdown.vue";
</script>

<script lang="ts">
import {defineComponent} from "vue";
import * as ApiManager from "@/network/ApiManager"
import * as AlertManager from "@/datamanagers/AlertsManager"
import {actionArrayChanged, getPriorityStrings, zip} from "@/utils";
import {liveViewEditorState} from "@/dtos/AppState";

export default defineComponent({
  name: "NewOrderModal",
  data() {
    return {
      nodeSequence: [] as any[],
      orderRequestPriority: 1, //high priority
      vehicleId: "",
      priorityValues: getPriorityStrings(this),
      liveViewEditorState,
    }
  },
  props:{
    nodes:
        {
          type: Number,
          default: null
        },
    vehicles:
        {
          type: Map,
          default: []
        }
  },
  watch: {
    "liveViewEditorState.locale"(newValue, oldValue) {
      this.priorityValues = getPriorityStrings(this)
      console.log("Lang updated", newValue, this.priorityValues)
    },
    nodes: {
      handler(newValue, oldValue) {
        if (newValue.length == 0) this.nodeSequence = []
        else {
          this.nodeSequence.push({
            nodeId: newValue.at(-1).nodeId,
            nodeDescription: newValue.at(-1).nodeDescription,
            defaultActions: true
          })
        }
      },
      deep: true
    }
  },
  computed:{},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    shiftNodeInTheList(position: number, inc: number) {
      const shiftedNode = this.nodeSequence[position];
      const nextNode = this.nodeSequence[position + inc];
      this.nodeSequence[position] = nextNode;
      this.nodeSequence[position + inc] = shiftedNode;
    },
    removeNodeFromSequence(nodeIdx: number) {
      this.nodeSequence.splice(nodeIdx, 1);
    },
    submitOrder(){
      if (this.nodeSequence.length > 0) {
        const nodes = this.nodeSequence.map((value: any) => {
          const actionNode = {
            node_id: value.nodeId,
            default_actions: value.defaultActions,
            actions: []
          }
          if (!value.defaultActions) {
            actionNode.actions = value?.actions?.map((actionVal: any) => {
              return {
                actionId: actionVal.action.actionId,
                actionParameters: actionVal.actionParameters
              }
            }) ?? []
          }
          return actionNode
        })
        ApiManager.createOrderRequest(nodes, new Date().toISOString(), String(this.vehicleId),
            this.orderRequestPriority,
            () => {
            },
            ((id: string) => {
              console.log("Created order request successfully")
              this.closeModal()
            }))
      } else {
        // @ts-ignore
        AlertManager.showAlert(AlertManager.createWarningAlert(this.$t("str_can_not_submit_order"),
            // @ts-ignore
            this.$t("str_order_should_have_2_nodes")))
      }

    },
    setVehicleId(selectedId: string) {
      this.vehicleId = selectedId
    },
    setActions(idx: number, actions: any[]) {
      const formattedActions = this.formatActionsForActionsView(actions)
      const dataChanged = actionArrayChanged(this.nodeSequence?.at(idx)?.actions, formattedActions)
      if (dataChanged) {
        this.nodeSequence.at(idx).actions = formattedActions
      }
    },
    formatActionsForActionsView(actions: any[]) {
      return actions?.map((obj: any) => {
        obj.action = {actionId: obj.actionId}
        const mergedParamArray = [...obj.actionParameters.reduce((map: any, obj: any) =>
            map.set(obj.key, obj), new Map()).values()];
        obj.action.predefined_action = true
        return {action: obj.action, actionParameters: mergedParamArray}
      }) ?? []
    },
    saveQuickRoute() {
      const nodes = this.nodeSequence.map((value: any) => {
        const actionNode = {
          node_id: value.nodeId,
          default_actions: value.defaultActions,
          actions: []
        }
        if (!value.defaultActions) {
          actionNode.actions = value?.actions?.map((actionVal: any) => {
            return {
              actionId: actionVal.action.actionId,
              actionParameters: actionVal.actionParameters
            }
          }) ?? []
        }
        return actionNode
      })
      ApiManager.saveOrderTemplate(nodes,
          () => {
            // @ts-ignore
            const alert = AlertManager.createWarningAlert(this.$t("str_quick_route_not_saved"), this.$t("str_quick_route_not_saved1"))
            AlertManager.showAlert(alert)
          },
          ((id: string) => {
            // @ts-ignore
            const alert = AlertManager.createSuccessAlert(this.$t("str_quick_route_saved"))
            AlertManager.showAlert(alert)
            console.log("Saved quick order")
          }))
    }
  },
})


</script>

<style scoped>
</style>
