<template>
  <div class="color_background_light " style="height:92%">
    <div class="my-auto  p-4 h-full ">
      <div class="flex flex-col fullscreen justify-between">
        <div class="flex w-auto flex-col pb-16">
          <div class="w-full h-full ">
            <ul role="list"
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              <li v-for="route in savedRoutes" :key="route.routeId" class="relative">
                <quick-route-item :saved-route="route" :nodes="nodes" @orderRequestSelected="confirmOrder"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <order-confirmation-popup v-if="selectedRoute != null" :nodes="nodes" :saved-route="selectedRoute"
                                :vehicles="vehicleStates"
                                @orderConfirmed="(orderConfirmed: boolean, order: any)=> {if (orderConfirmed) submitOrder(order);
                                                                    selectedRoute=null}"/>
    </div>
  </div>
</template>

<script setup lang="ts">

import OrderConfirmationPopup from "@/components/ordertemplate/OrderConfirmationPopup.vue";
</script>


<script lang="ts">
import {defineComponent} from 'vue'
import panZoom from 'panzoom';
import {CanvasHelper, LiveViewHelper} from '@/helpers/LiveViewHelper'
import * as ApiManager from "../network/ApiManager";
import {arrToXY, prod_safe_log} from '@/utils'
import {VehicleState, vehicleStates} from '@/dtos/VehicleState'
import VehicleShortView from "@/components/liveview/VehicleInfoShort.vue";
import NewNodeModal from "@/components/liveview/NewNodeModal.vue"
import NewEdgeModal from "@/components/liveview/NewEdgeModal.vue"
import {TrajectoryData} from '@/helpers/TrajectoryHelper';
import * as AlertManager from '@/datamanagers/AlertsManager'
import {liveViewEditorState} from "@/dtos/AppState";
import NewOrderModal from "@/components/liveview/NewOrderModal.vue";
import {graphDebugState} from "@/datamanagers/ReactiveStates";
import QuickRouteItem from "@/components/ordertemplate/QuickRouteItem.vue";

export default defineComponent({
  name: "QuickRoutes",
  data() {
    return {
      selectedRoute: null as unknown as any,
      savedRoutes: [],
      nodes: new Map<number, any>(),
      vehicleStates,
    };
  },
  components: {
    QuickRouteItem
  },
  methods: {
    getAllOrderTemplates() {
      ApiManager.getAllOrderTemplates(
          () => {
          },
          (data: []) => {
            this.savedRoutes = data
          }
      )
    },
    confirmOrder(nodes: []) {
      this.selectedRoute = {nodes: nodes, vehicleId: ""}
    },
    submitOrder(order: { nodes: [], vehicleId: string, priority: number }) {
      const nodes = order.nodes.map((value: any) => {
        const actionNode = {
          node_id: value.nodeId,
          default_actions: value.defaultActions,
          actions: []
        }
        if (!value.defaultActions) {
          actionNode.actions = value?.actions?.map((actionVal: any) => {
            return {
              actionId: actionVal.action.actionId,
              actionParameters: actionVal.actionParameters
            }
          }) ?? []
        }
        return actionNode
      })
      ApiManager.createOrderRequest(nodes, new Date().toISOString(), String(order.vehicleId),
          order.priority,
          () => {
          },
          ((id: string) => {
            console.log("Created order request successfully")
          }))
    },
  },
  mounted() {
  },
  created() {
    ApiManager.subscribeToUpdates([[ApiManager.sse_message_types.order_template_list_updated, this.getAllOrderTemplates]])
    this.getAllOrderTemplates()
    ApiManager.getNodes(
        () => {
          // @ts-ignore
          AlertManager.showAlert(AlertManager.createErrorAlert(this.$t("str_error_fetching_nodes"), this.$t("str_try_again")))
          prod_safe_log('nodes could not be requested')
        },
        (data: any) => {
          this.nodes.clear()
          data.nodes.forEach((node: any) => {
            this.nodes.set(node.nodeId, node)
          })
        });
  },
})
</script>

<style>
</style>
