<template>
  <Menu>
    <div class="relative">
      <MenuButton class=" button2 inline-flex items-center rounded-md px-4 py-2 mx-1 text-sm font-medium">
        <EllipsisVerticalIcon class="ml-2 -mr-1 h-5 w-5 color_enabled" aria-hidden="true" />
      </MenuButton>
      <MenuItems
        class="absolute top-[100%] right-0 flex flex-col max-h-60 overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-left z-20">
        <MenuItem v-for="option in options" v-slot="{ active }" @click="option.handler">
          <span
            :class='{ "text-white bg-indigo-600": active, "text-gray-900 relative cursor-default select-none py-2 px-4 whitespace-nowrap": true }'>
            {{ option.title }}
          </span>
        </MenuItem>
      </MenuItems>
    </div>
  </Menu>
</template>

<script setup lang="ts">
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OptionsDropdown",
  data() {
    return {

    }
  },
  props: {
    options:
    {
      type: Array,
      default: [
        {
          title: "No actions possible",
          handler: () => { }
        }
      ]
    },
  },
  watch: {

  },
  methods: {}
})
</script>

<style scoped></style>
